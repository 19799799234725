import React from 'react'

import { defaultError } from 'common/mocks/ResponseError'
import { ListDescription } from 'components/ListDescription/ListDescription'
import { CheckoutBaseBridge } from 'config/bridge/CheckoutBaseBridge'
import { useLocalErrorHandler } from 'hooks/useLocalErrorHandler'
import { AnalyticsLandingPage } from 'pages/landingPage/Home/Home.tags'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { Icons } from 'utils/icons'

import { Text } from '@interco/inter-ui/components/Text'

import { ContentPayment } from './PaymentMethod.styles'

interface PaymentMethodProps {
  amount: number
  number: number
  customAction?: () => void
}

export const PaymentMethod = ({ amount, number, customAction }: PaymentMethodProps) => {
  const handlerError = useLocalErrorHandler()

  const formOfPayment = useBoundState((state) => state.formOfPayment)
  const setFormOfPayment = useBoundState((state) => state.setFormOfPayment)

  const walletIcons = {
    payment: <Icons.Money width={24} height={24} color="var(--gray500)" />,
    edit: <Icons.Edit width={24} height={24} color="var(--primary500)" />,
    debit: <Icons.Wallet width={24} height={24} color="var(--primary500)" />,
    credit: <Icons.CreditCard width={24} height={24} color="var(--primary500)" />,
    right: <Icons.ChevronRight width={24} height={24} color="var(--primary500)" />,
  }

  const formOfPaymentIcon = (iconWallet: string) => {
    let icon: React.ReactElement | undefined

    switch (iconWallet) {
      case 'DEBIT':
        icon = walletIcons.debit
        break

      case 'CREDIT':
        icon = walletIcons.credit
        break

      default:
        icon = undefined
        break
    }

    return icon
  }

  const openChoicePaymentForm = async () => {
    try {
      const additionalFields = {
        number: `${number}`,
        productName: 'DEFAULT',
        origin: 'FLUXO_PRATELEIRA',
      }

      const additionalQueryRequestParams = additionalFields

      const response = await CheckoutBaseBridge.openWallet(
        `${amount}`,
        'CAPITALIZATION',
        additionalFields,
        additionalQueryRequestParams,
      )

      AnalyticsLandingPage({
        ref_type: 'botão',
        content_name: response.title,
        action_id: 'selecionar pagamento',
        payment: response.type,
      })

      setFormOfPayment(response)
    } catch (error) {
      handlerError(error as Error, 'Hiring.openWallet', {
        ...defaultError,
        route: TypesRoutes.LANDING_PAGE_HOME,
      })
    }
  }

  return (
    <ContentPayment>
      <ListDescription
        data-testid="payment-methods"
        iconLeft={formOfPaymentIcon(formOfPayment.type)}
        iconRight={formOfPayment.type ? walletIcons.edit : walletIcons.right}
        withBorder
        borderColor="var(--primary400)"
        onClick={customAction || openChoicePaymentForm}
      >
        <Text
          variant="body-3"
          bold
          colorWeight={500}
          color={formOfPayment.type ? 'typography' : 'primary'}
        >
          {formOfPayment.title}
        </Text>
        {formOfPayment.subTitle && (
          <Text variant="body-3" style={{ marginTop: '8px' }}>
            {formOfPayment.subTitle}
          </Text>
        )}
      </ListDescription>
    </ContentPayment>
  )
}
