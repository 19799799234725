import {
  IWbContractWalletAndroid,
  IWbContractWalletIOS,
  IWbOpenWalletAndroid,
  IWbOpenWalletIOS,
  IWbWalletTransactionType,
  interWbWallet,
} from '@interco/inter-webview-bridge'

import NewRelicUtils from '../monitoring/NewRelicUtils'
import BaseBridge from './BaseBridge'

export interface ContractWalletResult {
  transactionId: string
  status: string
}

export interface ChosenPaymentResult {
  title: string
  subTitle: string
  type: string
}

export class CheckoutBaseBridge {
  private static openWalletResponse?: IWbOpenWalletAndroid | IWbOpenWalletIOS

  /**
   * Método para invocar a seleção de formas de pagamento.
   * @param valor em centavos.
   * @param walletClientId tipo de transação, se CONSORCIO ou RECURRENCE.
   * @param additionalFields campo contendo informações adicionais, chave|valor.
   * @param additionalQueryRequestParams campo contendo informações adicionais, chave|valor.
   * @returns informações referentes ao método de pagamento selecionado.
   */
  static async openWallet(
    amount: string,
    walletClientId: 'CAPITALIZATION',
    additionalFields: Record<string, string>,
    additionalQueryRequestParams: Record<string, string>,
  ): Promise<ChosenPaymentResult> {
    if (BaseBridge.isBrowser())
      return {
        title: `Crédito Inter`,
        subTitle: 'Não cobrado em ambiente de desenvolvimento.',
        type: 'CREDIT',
      } as ChosenPaymentResult

    let result: ChosenPaymentResult

    const price = Number.parseFloat(amount) * 100

    try {
      const response = await interWbWallet.openWallet(
        walletClientId,
        `${price.toFixed(0)}`,
        additionalFields,
        additionalQueryRequestParams,
      )

      CheckoutBaseBridge.openWalletResponse = response

      const paymentMethodTitle =
        (response as IWbOpenWalletAndroid)?.selectedPaymentMethod?.paymentMethodTitle ||
        (response as IWbOpenWalletIOS)?.method?.title

      const paymentMethod =
        (response as IWbOpenWalletAndroid)?.selectedPaymentMethod?.type ||
        (response as IWbOpenWalletIOS)?.method?.type

      result = {
        title: paymentMethodTitle,
        subTitle: '',
        type: paymentMethod,
      }

      return result
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'CheckoutBaseService.openWallet',
      })

      return Promise.reject(error)
    }
  }

  /**
   * Método invocado para finalizar a contratação via wallet.
   * @param amount valor da transação em reais.
   * @returns o status e o id da transação de contratação.
   */
  static async contractWallet(amount: string): Promise<ContractWalletResult> {
    if (BaseBridge.isBrowser()) {
      return Promise.resolve({
        transactionId: '',
        status: 'SUCCESS',
      } as ContractWalletResult)
    }

    if (!this.openWalletResponse) throw new Error('Erro ao resgatar os dados da forma de pagamento')

    const price = Number.parseFloat(amount)

    const transactionType: IWbWalletTransactionType = {
      code: '16',
      checkType: 5,
      description: 'AUTORIZACAO_SEGURO',
      value: price,
    }

    try {
      const result = await interWbWallet.contractWallet(this.openWalletResponse, transactionType)

      if (result.status === 'FAILURE') throw new Error(result.error)

      const transactionId =
        (result as IWbContractWalletAndroid)?.walletLibraryCheckoutResult?.walletClientId ||
        (result as IWbContractWalletIOS)?.response?.clientTransactionId

      return {
        transactionId,
        status: result.status,
      }
    } catch (error) {
      const errorMessage = error as Error

      if (errorMessage.message !== 'walletCheckoutManagerDidUserCancelISafeAuthentication') {
        NewRelicUtils.noticeError(errorMessage, {
          errorCodeRef: 'CheckoutBaseService.contractWallet',
        })
      }

      return Promise.reject(error)
    }
  }
}
