import { TagEventsEnum } from "common/enums/TagEventsEnum"
import BaseBridge from "config/bridge/BaseBridge"

export const AnalyticsReceipt = ({ref_type, action_id, value, payment} : {ref_type: string, action_id: string, value: string, payment: string}) => {
  BaseBridge.requestAnalytics(TagEventsEnum.CAPITALIZACAO_FLUXO, {
    ref_figma: '5',
    screen: 'comprovante',
    ref_type,
    content_action: 'dado carregado',
    action_id,
    value,
    currency: 'BRL',
    payment,
    product: 'sortezinha',
    flow: 'prateleira',
  })
}
