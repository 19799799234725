import { ErrorIconEnum } from 'common/enums/ErrorIcon'
import { ReceiptStatusEnum } from 'common/enums/ReceiptStatus'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import CheckoutService from 'service/CheckoutService'
import useBoundState from 'store'
import { ErrorDetails } from 'store/error/types'
import { errorHandling, redirectRoute } from 'store/utils/provider'
import { StateCreator } from 'zustand'

import {
  CheckoutState,
  ICheckoutProcessingRequest,
  ICheckoutProcessingResponse,
  IFormOfPayment,
} from './types'

export const createCheckoutSlice: StateCreator<CheckoutState, [], [], CheckoutState> = (set) => ({
  formOfPayment: {
    title: 'Escolher forma de pagamento',
    subTitle: '',
    type: '',
  } as IFormOfPayment,

  checkoutResponse: {} as ICheckoutProcessingResponse,

  getCheckoutResponse: async (payload) => {
    try {
      let response = {} as ICheckoutProcessingResponse
      let counter = 0
      const MAX_ATTEMPTS = 3

      do {
        response = (await CheckoutService.checkoutProcessing(
          payload.capitalizationLot,
        )) as ICheckoutProcessingResponse
        counter += 1

        if (
          (!response.status || response.status === ReceiptStatusEnum.PROCESSING) &&
          counter < MAX_ATTEMPTS
        ) {
          await new Promise((resolve) => setTimeout(resolve, 3000))
        }
      } while (
        (!response.status || response.status === ReceiptStatusEnum.PROCESSING) &&
        counter < MAX_ATTEMPTS
      )

      if (!response.status) {
        const errorDetails: ErrorDetails = {
          title: 'Erro no processamento das Sortezinhas',
          subTitle: 'Volte mais tarde.',
          icon: ErrorIconEnum.WARNING,
          route: TypesRoutes.REQUEST_GO_BACK,
        }

        useBoundState.getState().setHasError(errorDetails)
      }

      set({ checkoutResponse: response })
      processResponseStatus(payload, response.status)
    } catch (error) {
      errorHandling(error as Error, 'ProcessingTickets.getProcessingTickets', undefined, true)
    }
  },

  setFormOfPayment: (formOfPayment) => set(() => ({ formOfPayment })),
})

const processResponseStatus = (payload: ICheckoutProcessingRequest, status: ReceiptStatusEnum) => {
  const errorDetails: ErrorDetails = {
    title: 'Erro no processamento das Sortezinhas',
    subTitle: 'Volte mais tarde.',
    icon: ErrorIconEnum.WARNING,
    route: TypesRoutes.LANDING_PAGE_HOME,
  }

  switch (status) {
    case ReceiptStatusEnum.PROCESSING:
      redirectRoute({ ...payload, pathname: TypesRoutes.LANDING_PAGE_RECEIPT })
      break
    case ReceiptStatusEnum.SUCCESS:
      redirectRoute({ ...payload, pathname: TypesRoutes.LANDING_PAGE_RECEIPT })
      break
    case ReceiptStatusEnum.ERROR:
      useBoundState.getState().setHasError(errorDetails)
      break
    default:
      useBoundState.getState().setHasError(errorDetails)
  }
}
