import { DeepLinksEnum } from 'common/enums/DeepLink'
import { useHistory, useLocation } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'

export function PathQueryParams() {
  const location = useLocation()
  const history = useHistory()

  const getTickets = useBoundState((state) => state.getTickets)
  const getLandingPage = useBoundState((state) => state.getLandingPage)
  const setQueryParams = useBoundState((state) => state.setQueryParams)
  const setUtmCampaign = useBoundState((state) => state.setUtmCampaign)

  const whitelist = ['/']

  const handler = () => {
    if (whitelist.includes(location.pathname)) {
      const searchParams = new URLSearchParams(location.search)

      return setPathParams(location.pathname, searchParams)
    }

    return false
  }

  const setPathParams = (pathname: string, params: URLSearchParams) => {
    let hasParams = false

    if (params.getAll.length > 0 && pathname === '/') {
      const urlParams = {
        url: params.get('url') || undefined,
        utm_campaign: params.get('utm_campaign') || undefined,
      }

      if (urlParams.url && urlParams.url === DeepLinksEnum.AFTER_SALES) {
        getTickets({
          onTransmission: (noHasAfterSales) => {
            noHasAfterSales
              ? history.push(TypesRoutes.SUBSCRIPTION)
              : history.push(TypesRoutes.AFTER_SALES)
          },
        })

        setQueryParams(urlParams.url)
        hasParams = true
      }

      if (urlParams.url && urlParams.url === DeepLinksEnum.LANDING_PAGE) {
        getLandingPage({
          history,
          pathname: TypesRoutes.LANDING_PAGE_HOME,
        })

        setQueryParams(urlParams.url)
        hasParams = true
      }

      if (urlParams.utm_campaign) {
        setUtmCampaign(urlParams.utm_campaign)
      }
    }

    return hasParams
  }

  return handler
}
