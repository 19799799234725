import { useEffect, useState } from 'react'

import { Svg } from 'assets/svg/svg'
import { ActionIconEnum } from 'common/enums/ActionIcon'
import { PageTitles } from 'common/enums/PageTitles'
import { ScratchCard } from 'components'
import BaseBridge from 'config/bridge/BaseBridge'
import { useNavBar } from 'hooks/useNavBar'

import { Button } from '@interco/inter-ui/components/Button'
import { Tag } from '@interco/inter-ui/components/Tag'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './Subscription.styles'
import {
  AnalyticsSubscriptionAccess,
  AnalyticsSubscriptionConfirm,
  AnalyticsSubscriptionHeader,
} from './Subscription.tags'

export const Subscription = () => {
  const [isAnimated, setIsAnimated] = useState(false)

  useNavBar({
    navbarTitle: PageTitles.SCRATCHCARD,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
      rightIcon: ActionIconEnum.FAQ,
    },
    backListener: () => BaseBridge.requestGoBack(),
    backButtonCallback: () => AnalyticsSubscriptionHeader('42', 'back'),
    helpButtonCallback: () => AnalyticsSubscriptionHeader('43', 'ajuda'),
  })

  useEffect(() => {
    AnalyticsSubscriptionAccess()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setIsAnimated(!isAnimated)
    }, 100)
  }, [])

  const handleCheckout = () => {
    AnalyticsSubscriptionConfirm()

    BaseBridge.isAndroid()
      ? BaseBridge.openDeepLink('bancointer://recarga')
      : BaseBridge.requestGoBack()
  }

  const renderStickFooter = (
    <>
      <S.Footer>
        <Button fullWidth onClick={handleCheckout}>
          Quero uma Sortezinha
        </Button>
      </S.Footer>
    </>
  )

  return (
    <S.Container>
      <S.List className={isAnimated ? 'animated' : ''}>
        <ScratchCard scale={300}>{Svg.Gift}</ScratchCard>
        <ScratchCard scale={300}>{Svg.GiftSuccess}</ScratchCard>
        <ScratchCard scale={300}>{Svg.Gift}</ScratchCard>
      </S.List>

      <S.Header>
        <Tag theme="green">Por apenas R$ 3,00</Tag>
        <Text variant="headline-h1" as="h1" semiBold>
          Ative sua sorte e ganhe até R$ 100,00 na hora!
        </Text>

        <S.HeaderList>
          <Text variant="body-3" as="li" colorWeight={500}>
            Concorra também a 6 sorteios de R$ 7.000,00 ao longo de um ano.
          </Text>
          <Text variant="body-3" as="li" colorWeight={500}>
            Receba 50% de cashback do valor pago após os sorteios.
          </Text>
        </S.HeaderList>
      </S.Header>

      {renderStickFooter}
    </S.Container>
  )
}
